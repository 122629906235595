body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Mitogen Display', 'DMSans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.workingclose {
  margin-top: 40px;
  text-align: justify;
  font-family: DM Sans;
  font-weight: 500;
  color: #2c3470;
  font-size: 23px;
  width: 100%;
  text-align: justify;
  margin-bottom: 7%;
  line-height: 115%;
}

.hiring_through {
  text-align: center;
  font-family: DM Sans;
  color: #808080;
  font-size: 32px;
  width: 620px;
  margin-bottom: 40px;
  font-weight: 500;
  margin-top: 20px;
}

.staffing_needs {
  width: 250px;
  color: #2c3470;
  font-size: 30px;
  font-weight: 500;
}



.the_team {
  font-family: 'Mitogen Display', sans-serif;
  font-size: 95px;
  color: #2c3470;
  line-height: 90%;
  width: 100%;
  text-align: center;
  height: max-content;
  margin-top: 2%;
  justify-content: center;
}


.the_teamm {
  font-family: 'Mitogen Display', sans-serif;
  font-size: 95px;
  color: #2c3470;
  line-height: 90%;
  width: 100%;
  text-align: center;
  height: max-content;
  margin-top: 0%;
  justify-content: center;
}

.staffingtitle {
  font-family: 'Mitogen Display', sans-serif;
  font-size: 85px;
  color: #2c3470;
  line-height: 100%;
  width: 100%;
  text-align: center;
  margin-top: -10%;
}

.Meeting_your {
  font-family: 'Mitogen Display', sans-serif;
  font-size: 90px;
  color: #2c3470;
  width: 80%;
  line-height: 110%;
  text-align: center;
}



.explore_partnership {
  font-family: 'Mitogen Display', sans-serif;
  font-size: 50px;
  color: #2c3470;
  width: 800px;
  line-height: 110%;
}



.Work_with_us {
  margin-top: 2%;
  color: #2c3470;
  margin-bottom: 5%;
  font-size: 26px;
  text-align: justify;
  width: 570px;
  margin-left: 0.5%;
  line-height: 110%;
}

.briefly {
  font-size: 42px;
  color: #424ea8;
  width: 600px;
  font-family: DM Sans;
  font-weight: 500;
}

.achieve_and_communication {
  color: #2c3470;
  font-size: 110px;
  font-family: 'Mitogen Display', sans-serif;
  text-align: center;
  width: 50%;
  line-height: 110%;
  margin-top: 5%;
}

.WantToBe {
  color: #2c3470;
  font-size: 95px;
  font-family: 'Mitogen Display', sans-serif;
  text-align: center;
  width: 54%;
  line-height: 110%;
  margin-top: 5%;

}

.WeAre {
  color: #2c3470;
  font-size: 140px;
  font-family: 'Mitogen Display', sans-serif;
  text-align: center;
  width: 100%;
  line-height: 110%;
  margin-top: 5%;
  margin-bottom: 2%;
}

.OurServices {
  color: #2c3470;
  font-size: 150px;
  font-family: 'Mitogen Display', sans-serif;
  text-align: center;
  width: 50%;
  line-height: 100%;
  margin-top: 3%;
}

.AboutUs {
  color: #2c3470;
  font-size: 110px;
  font-family: 'Mitogen Display', sans-serif;
  text-align: center;
  font-size: 150px;
}



.We_understand {
  text-align: justify;
  font-family: DM Sans;
  color: #2c3470;
  font-size: 28px;
  width: 100%;
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: 40px;
}

.Ready-Partnership-Customer {
  color: #2c3470;
  font-size: 150px;
  font-family: 'Mitogen Display', sans-serif;
  text-align: center;
  width: 75%;
  line-height: 110%;
  margin-top: 5%;
}

.Become_Dedicated {
  color: #2c3470;
  font-size: 150px;
  font-family: 'Mitogen Display', sans-serif;
  padding-top: 26%;
  width: 100%;
  line-height: 110%;
}

.StepByStep {
  width: max-content;
  color: #f4b11c;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 8%;
  font-family: 'DM Sans', sans-serif;
  font-style: italic;
}

.what_make_us {
  color: #2c3470;
  font-size: 40px;
  font-family: 'Mitogen Display', sans-serif;
  font-weight: bold;
  width: 80%;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 5%;
  line-height: 110%;
}

.subtitle_six_box {
  color: #2c3470;
  font-size: 40px;
  font-family: 'Mitogen Display', sans-serif;
  font-weight: 500;
  width: 80%;
  text-align: left;
  margin-top: 5%;
  line-height: 110%;
}

.subtitle_six_text {
  color: #424ea8;
  font-size: 21px;
  width: 440px;
  text-align: justify;
  font-family: DM Sans;
  font-weight: 500;
  margin-top: 2%;
}

.WePromote {
  color: #424ea8;
  font-size: 26px;
  width: 55%;
  text-align: center;
  font-family: DM Sans;
  font-weight: 500;
  margin-top: 3%;

}

.team_names {
  font-size: 30px;
  font-family: 'Mitogen Display', sans-serif;
  color: #2c3470;
  width: max-content;
  text-align: center;
  font-weight: 500;
  line-height: 100%;
}

.text_six_box {
  font-size: 30px;
  font-family: 'DM Sans', sans-serif;
  color: #2c3470;
  width: 330px;
  text-align: left;
  font-weight: 500;
  line-height: 110%;
  margin-top: 3%;

}

.team_rol {
  font-size: 28px;
  font-family: 'DM Sans', sans-serif;
  color: #424ea8;
  font-weight: bold;
  line-height: 100%;
  text-align: left;
  width: max-content;
}

.Engaging_subtitle {
  font-size: 170%;
  color: #2c3470;
  font-family: 'DM Sans', sans-serif;
  width: 40%;
  text-align: center;
  margin-left: 0%;
  position: absolute;
  line-height: 110%;
}

.IT_Support {
  font-weight: bold;
  font-size: 70px;
  color: #2c3470;
  font-family: 'Mitogen Display', sans-serif;
  margin-top: 3%;
  text-align: left;
}

.Team_Support {
  font-weight: bold;
  font-size: 70px;
  color: #2c3470;
  font-family: 'Mitogen Display', sans-serif;
  margin-top: 3%;
  text-align: center;
  padding-left: 30%;
}



.text_careers_info {
  width: 60%;
  font-size: 28px;
  font-family: 'DM Sans', sans-serif;
  color: #424ea8;
  text-align: justify;
  font-weight: 500;
}

.what_make_us_phrase {
  font-size: 50px;
  font-family: 'DM Sans', sans-serif;
  color: #424ea8;
}

.info_six_sox_OurServices {
  font-size: 25px;
  font-family: 'DM Sans', sans-serif;
  color: #424ea8;
  text-align: justify;
  width: 340px;
  font-weight: 500;
}


/* .O1 {
  font-size: 40px;
  color: #2c3470;
  width: max-content;
  font-family: 'Mitogen Display', sans-serif;
} */



.Join_our {
  font-size: 110px;
  font-weight: 500;
  color: #2c3470;
  font-family: 'Mitogen Display', sans-serif;
  width: 80%;
  line-height: 95%;
}

.oportunity {
  font-size: 26px;
  color: #2c3470;
  font-weight: 500;
  width: 80%;
  line-height: 110%;
  margin-top: 2%;
}

.ContactLocation {
  font-size: 50px;
  color: #161a38;
  font-family: 'Mitogen Display', sans-serif;
  font-weight: 500;
  margin-top: 5%;
  margin-bottom: 0%;
}

.directions {
  color: #424ea8;
  font-size: 26px;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  height: 100%;
  line-height: 170%;
}

.ContactInfo {
  color: #424ea8;
  font-size: 26px;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  height: 80%;
  margin-top: 0%;
  line-height: 280%;
}

.ContactName {
  font-size: 150%;
  color: rgb(109, 106, 106);
  font-family: 'Mitogen Display', sans-serif;
  font-weight: 500;
  margin-bottom: 2%;
  margin-top: 5%;
}

.infoboxfooter {
  color: #424ea8;
  font-size: 110%;
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  height: 100%;
  line-height: 130%;
}

.inquiries {
  font-size: 28px;
  color: #2c3470;
  width: 45%;
  font-family: 'DM Sans', sans-serif;
  text-align: justify;
  font-weight: 500;
  margin-top: 2%;
}

.stars {
  color: #424ea8;
  font-size: 40px;
  padding-bottom: 10px;
}

.subtittleCarousel {
  font-size: 34px;
  color: #2c3470;
  font-weight: bold;
  line-height: 110%;
  width: 100%;
}

.titlecarousel {
  font-size: 25px;
  color: #424ea8;
}

.carrnametext {
  font-size: 18px;
  padding-top: 20px;
  color: #2c3470;
  text-align: justify;
  font-weight: 500;
  width: 100%;
}

.boxcontactandlocations {
  border-radius: 40px;
  width: 450px;
  height: 230px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}


/* btn send */


.btnsendandtest {
  cursor: pointer;
  position: relative;
  padding: 10px 50px;
  border-radius: 5px;
  background: #f4b11c;
  font-size: 30px;
  font-family: 'DM Sans', sans-serif;
  color: white;
  width: 250px;
  margin-top: 16%;
  margin-bottom: 4%;
  margin-left: 0%;
}

.btnsendandtest:hover {
  background: #2c3470;
  color: white;
}

.applynowbutton {
  cursor: pointer;
  position: relative;
  padding: 10px 50px;
  border-radius: 5px;
  background: #f4b11c;
  font-size: 30px;
  font-family: 'DM Sans', sans-serif;
  color: white;
  width: 250px;
  margin-top: 16%;
  margin-bottom: 4%;
  margin-left: 35%;
}

.applynowbutton:hover {
  background: #2c3470;
  color: white;
}

.btnapplynow {
  cursor: pointer;
  position: relative;
  padding: 5px 50px;
  border-radius: 5px;
  background: #f4b11c;
  font-size: 30px;
  font-family: 'DM Sans', sans-serif;
  color: white;
  width: 250px;
  margin-top: 10%;
  margin-bottom: 0%;
  margin-left: 15%;
}

.btnapplynow:hover {
  background: #2c3470;
  color: white;
}

/* btn send */


.btnbluecontact {
  cursor: pointer;
  position: relative;
  padding: 5px 15px;
  background: #2c3470;
  font-size: 30px;
  font-family: 'DM Sans', sans-serif;
  color: white;
  width: max-content;
  margin-top: 6%;
  margin-bottom: 4%;
  border-radius: 5px;
  margin-left: 30%;
}

.btnbluecontact:hover {
  background: #f4bb1c;
  color: white;
}

.contactandsucces {
  cursor: pointer;
  position: relative;
  padding: 5px 15px;
  background: #2c3470;
  font-size: 30px;
  font-family: 'DM Sans', sans-serif;
  color: white;
  width: max-content;
  margin-top: 6%;
  margin-bottom: 4%;
  border-radius: 5px;
  margin-left: 0%;
}

.contactandsucces:hover {
  background: #f4bb1c;
  color: white;
}


/*css mediaqueries changes*/
.ImagePerson1Home {
  flex-direction: column;
  width: 32%;
  margin-top: 5%;
  height: max-content;
  border-radius: 20px;
}

.staffing {
  margin-top: 54px;
  height: max-content;
  flex-direction: row;
  justify-content: space-evenly;
}

.staffingflexbox {
  margin-top: 2%;
  margin-left: 15%;
  padding-top: 8%;
  flex-direction: column;
  width: 520px;
  justify-content: left;
}

.navbarflex {
  z-index: 1000;
  background: white;
  display: flex;
  justify-content: space-between;
  height: max-content;
  position: fixed;
  top: 0%;
  width: 100%;

}

.navbarfivesections {
  width: max-content;
  min-width: max-content;
  padding-top: 1.5%;
  height: max-content;
}

.hamburguerflex {
  visibility: hidden;
  margin-left: 0%;
}


.Officeimg {
  margin-top: 2%;
  width: 50%;
  margin-bottom: 3%;
}

.testimonies {
  border: 8px #2c3470 solid;
  width: 550px;
  height: 550px;
  padding: 2.5%;
  border-radius: 40px;
  position: relative;
  margin-left: 1%;
}

.carouselbox {
  height: max-content;
  width: 1300px;
  overflow: hidden;
}

.maindedicated {
  color: #fab11c;
  font-style: italic;
  font-size: 110px;
}

.map_and_contact_gray {
  background: #f2f2f2;
  height: max-content;
  justify-content: space-evenly;
  position: relative;
  margin-top: 2%;
  padding-top: 2%;
  margin-top: 5%;

}

.mapformwhite {
  background: white;
  height: max-content;
  justify-content: space-evenly;
  position: relative;
  margin-top: 15%;
  padding-top: 2%;
  margin-top: 2%;

}

.name {
  background-color: #fabb1c;
  width: 95%;
  border-color: 1px solid #fabb1c;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.About_us_1 {
  margin-left: 0%;
  margin-top: 10%;
  margin-right: 5%;
  margin-bottom: 0%;
  width: 60%;
  height: 50%;
}

.hiringtext {
  color: #666666;
  font-size: 30px;
  margin-bottom: 5%;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
}

.carouselboxfive {
  height: max-content;
  width: 1500px;
  overflow: hidden;
}

.achievesix {
  width: 1200px;
  height: max-content;
  justify-content: center;
  flex-direction: row;
  margin-top: 0%;
}



.teampics {
  width: 425px;
  height: 500px;
  background: #f2f2f2;
  position: relative;
  text-align: left;
  margin-left: 20px;
  flex-direction: column;
}

.contactbox {
  width: 40%;
  height: max-content;
  flex-direction: column;
}

.Our_Services {
  width: 1000px;
  border-radius: 20px;
  margin-top: 6%;
}

.ourservices {
  width: 1200px;
  height: max-content;
  margin-bottom: 15%;
  position: relative;

  display: flex;
  justify-content: center;
}

.Dude_1 {
  width: 1300px;
  position: absolute;
  z-index: 900;
  top: 5%;
  left: -28%;
}

.partner_perks {
  width: 100%;
  height: max-content;
  margin-left: 60%;
  flex-direction: column;

}

.whatmakeus {
  width: 1100px;
  height: max-content;
  justify-content: space-between;
  padding-top: 2%;
  padding-bottom: 2%;

}

.cardmakeus {
  width: 500px;
  background: #f2f2f2;
  border-radius: 40px;
  padding-top: 2%;
  padding-bottom: 2%;
  height: 720px;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
}

.Careerss {
  margin-top: 2%;
  width: 45%;
}

.Girl_2 {
  width: 700px;
  position: absolute;
  z-index: 900;
  top: 2%;
  right: 14%;
}

.becomeD {
  width: 800px;
  flex-direction: column;
  margin-left: 12%;
  height: 1800px;
}


.become_numbers {
  border: #cccccc 2px solid;
  height: max-content;
  padding: 3%;
  flex-direction: column;
  border-radius: 20px;
  margin-right: 10%;
}

.talk01 {
  font-size: 44px;
  font-family: 'Mitogen Display', sans-serif;
  color: #2c3470;
  margin-top: 4%;
}

.talk01sub {
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  font-family: 'DM Sans', sans-serif;
  color: #2c3470;
  width: 110%;

}

.prepare02 {
  margin-top: 9%;
  font-size: 44px;
  font-family: 'Mitogen Display', sans-serif;
  color: #424ea8;

}

.prepare02sub {
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  font-family: 'DM Sans', sans-serif;
  color: #424ea8;

}

.interview03 {
  margin-top: 13%;
  font-size: 44px;
  font-family: 'Mitogen Display', sans-serif;
  color: #5668e0;

}

.interview03sub {
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  font-family: 'DM Sans', sans-serif;
  color: #5668e0;

}

.hired04 {
  margin-top: 20%;
  font-size: 44px;
  font-family: 'Mitogen Display', sans-serif;
  color: #7a86e6;

}

.hired04sub {
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  font-family: 'DM Sans', sans-serif;
  color: #7a86e6;

}

.boxnumbers {
  width: 620px;
  margin-bottom: 5%;

}

.gototest {
  width: max-content;
  margin-left: 46.5%;
  margin-top: 25%;
}

.Form {
  width: 47%;
  height: 47%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.Contact {
  width: 50%;
  margin-bottom: 3%;
  margin-top: 3%;
}

.socialmedia {
  width: 40%;
  justify-content: space-between;
  flex-direction: row;
}

.socialmedia2 {
  width: 15%;
  justify-content: space-between;
  margin-left: 5%;
  flex-direction: row;
}

.contactlocations {
  margin-bottom: 6%;
  margin-top: 5%;
  height: max-content;
  width: 60%;
  justify-content: space-between;
}

.socialmediathree {
  justify-content: space-between;
  width: 100%;
  margin-left: 0%;
}

.Woman_2 {
  width: 400px;
  position: absolute;
  left: 0.5%;
  top: 4%;
}

.Woman_1 {
  margin-top: 0%;
  margin-left: -9%;
  width: 500px;

}

.boxImagen {
  width: 550px;
  height: max-content;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.sixImagen {
  border-radius: 40px;
  width: 450px;
  height: 250px;
  position: relative;
}

.boxcontactus {
  background: #f2f2f2;
  border-radius: 40px;
  width: 45%;
  height: 50%;
  flex-direction: column;
  text-align: center;
}

.mapsbgg {
  width: 28%;
  height: max-content;
  margin-left: 12%;
  padding: 1%;
  padding-bottom: 0%;
}

.boxdoublequotes {
  width: 100px;
  height: 100px;
  background: #2c3470;
  border-radius: 50%;
  top: -4%;
  right: -4%;
  position: absolute;
}

.icondoublequotes {
  padding: 12%;
  font-size: 75px;
  color: white;
}

.tobededicated {
  margin-left: 10%;
  width: max-content;
  color: #2c3470;
  font-size: 100%;
  flex-direction: column;
}

.boxabout {
  margin-top: 54px;
  height: max-content;
  flex-direction: row;
  justify-content: center;
}

.carousel5 {
  width: 450px;
  height: 670px;
  border-radius: 40px;
  margin-right: 1%;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.boxsixaboutus {
  padding-left: 15%;
  padding-top: 10%;
  padding-bottom: 10%;
  margin-top: -9%;
  border-radius: 50px;
  background-color: white;
  width: 550px;
}

.pictureTeam {
  width: 430px;
  padding: 4%;
}

.BoxJoinTeam {
  height: max-content;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.iconsfooter {
  color: #424ea8;
  cursor: pointer;
  font-size: 300%;
}

.iconsContact {
  color: #424ea8;
  cursor: pointer;
  font-size: 600%;
  width: 100%;
  border: 4px #424ea8 solid;
  border-radius: 20px;
  padding: 10%;
}

.iconsContact:hover {
  color: white;
  background-color: #424ea8;
}

.team_pics_box {
  margin-top: 5%;
  height: max-content;
  background: #f2f2f2;
  flex-direction: column;
  padding-bottom: 4%;
  align-items: center;
}

.boxteams {
  height: max-content;
  width: 100%;
  flex-direction: column;
  margin-top: 0%;
}

.image_aboutUs {
  border-radius: 30px;
  height: 250px;
  width: 100%;
}

.inputsForm {
  flex-direction: column;
  width: 100%;
}

.PartnersSponsors {

  width: 100%;
  height: 300px;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.Partners {

  width: max-content;
  height: max-content;
  flex-direction: column;
}

.flextwopartners {

  flex-direction: row;
  height: max-content;
}

.spacePartnersSponsors {
  border: 0px black solid;
}

.partners_sponsors_Font {
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 5px;
  width: 100%;
  height: max-content;

}

.MB {
  margin-bottom: 2.5%;
}

.fourpartners {
  flex-direction: column;
  width: 200px;

}

.MT {
  margin-top: -10%;
}

.foursponsors {
  flex-direction: column;
  width: 200px;

  height: 170px;
  justify-content: center;
}

.subtitlepartners {

  color: #424ea8;
  font-weight: 500;
  width: 80%;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.borderleftsponsord {
  border-left: 2px gray solid;
  padding-left: 1%;
}


















































@font-face {
  font-family: 'Mitogen Display';
  src: url('./fonts/Mitogen\ display/OTF/Mitogen Display.otf') format('opentype'),
    /* Para archivos OTF */
    url('./fonts/Mitogen\ display/TTF/Mitogen Display.ttf') format('truetype');
  /* Para archivos TTF */
}

@font-face {
  font-family: 'DM Sans';
  src: url('./fonts/DM_Sans/static/DMSans-Regular.ttf') format('truetype');
  /* Para archivos TTF */
}

/*tablet*/
@media only screen and (min-width: 768px) and (max-width: 1200px) {

  .ImagePerson1Home {
    visibility: visible;
    width: 60%;
  }

  .staffing_needs {
    width: 190px;
    font-size: 25px;
  }

  .Woman_1 {
    visibility: hidden;
  }

  .WantToBe {
    font-size: 75px;
    width: 100%;
    text-align: center;
  }

  .maindedicated {
    font-size: 69px;
    width: 75%;
  }

  .Woman_2 {
    visibility: hidden;
  }

  .map_and_contact_gray {
    flex-direction: column;
  }

  .About_us_1 {
    visibility: visible;
    height: 0%;
    width: 70%;
    margin-top: 20%;
    margin-bottom: 8%;
    align-items: center;
  }


  .achieve_and_communication {
    font-size: 60px;
    text-align: center;
    width: 65%;
    margin-top: 15%;
  }

  .achievesix {
    width: 530px;
    flex-direction: column;
  }

  .the_team {
    width: 100%;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
    align-items: center;
  }

  .the_teamm {
    width: 100%;
    flex-direction: column;
    margin-top: -5%;
    margin-bottom: 5%;
    align-items: center;
  }

  .mapformwhite {
    flex-direction: column;
  }

  .OurServices {
    font-size: 80px;
    width: 100%;
  }

  .Our_Services {
    width: 90%;
    margin-top: 15%;
  }

  .Dude_1 {
    visibility: hidden;
  }

  .Ready-Partnership-Customer {
    font-size: 75px;
    text-align: center;
    width: 100%;
  }

  .Team_Support {
    font-size: 60px;
    margin-top: 3%;
    text-align: center;
    padding-left: 0%;
  }

  .partner_perks {
    width: 100%;
    margin-left: 14%;
  }

  .whatmakeus {
    width: 400px;
    padding-top: 0%;
    padding-bottom: 0%;
    flex-direction: column;

  }

  .cardmakeus {
    width: 400px;
    margin-top: 10%;
    height: max-content;
    padding-top: 15%;
    margin-bottom: 6%;
  }

  .WeAre {
    font-size: 75px;
    width: 100%;
  }

  .text_careers_info {
    width: 80%;
    font-size: 25px;
  }

  .Girl_2 {
    visibility: hidden;
  }

  .Become_Dedicated {
    font-size: 75px;
    padding-top: 10%;
    width: 85%;
    text-align: center;

  }

  .StepByStep {
    width: max-content;
    font-size: 75px;
    width: 85%;
    text-align: center;
  }


  .inquiries {
    font-size: 26px;
    width: 70%;
    text-align: justify;
    font-weight: 500;
    margin-top: 5%;
    margin-bottom: 3%;
  }

  .contactlocations {
    margin-bottom: 0%;
    width: 70%;
    margin-top: 5%;
    flex-direction: column;
    align-items: center;
  }

  .carouselbox {
    height: max-content;
    width: 100%;
    overflow: hidden;
  }

  .explore_partnership {
    width: 100%;
    font-size: 45px;
    text-align: center;
  }

  .carouselboxfive {
    width: 730px;
  }

  .footer {
    height: 250px;
    flex-direction: row;
    margin-top: 0%;
    background-color: black;
  }

  .mapsbgg {
    width: 90%;
    margin-top: 10%;
    margin-left: 5%;
  }

  .staffingflexbox {
    margin-left: 0%;
    padding-top: 5%;
    width: 100%;
  }


  .contactbox {
    width: 100%;
    height: max-content;
    flex-direction: column;
    margin-top: 12%;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .teampics {
    margin-bottom: 4%;
  }

  .socialmedia {
    width: 70%;
    margin-left: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .testimonies {
    border: 4px #2c3470 solid;
    width: 470px;
    height: 650px;
  }

  .btnrsbox {
    width: 90px;
    height: 90px;
    background: #424ea8;
    cursor: pointer;
    border-radius: 10px;
    padding-left: 2.5%;
    padding-top: 2.5%;
  }

  .boxcontactus {
    margin-bottom: 20px;
    width: 90%;
    margin-top: 10%;
  }

  .btn-AN {
    cursor: pointer;
    position: relative;
    padding: 10px 50px;
    border-radius: 5px;
    background: #f4b11c;
    font-size: 30px;
    font-family: 'DM Sans', sans-serif;
    color: white;
    width: 250px;
    margin-top: 16%;
    margin-bottom: 4%;
    margin-left: 25%;
  }

  .btn-AN:hover {
    background: #2c3470;
    color: white;
  }

  .flexfourpoints {
    flex-direction: row;
    text-align: left;
    margin-left: 1%;
  }

  .btn-blue-contact {
    margin-left: 0%;
    margin-top: 0%;
  }

  .btnAN {
    margin-left: 28%;
    margin-bottom: 8%;
  }

  .briefly {
    width: 100%;
    text-align: center;
  }

  .AboutUs {
    font-size: 100px;
    width: 100%;
    text-align: center;
  }

  .btn-blue {
    margin-left: 37%;
    margin-top: 0;
  }

  .boxabout {
    height: 1000px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .WePromote {
    width: 80%;
    margin-bottom: 5%;
  }

  .Engaging_subtitle {
    font-size: 25px;
    width: 80%;
    margin-top: 2%;
    padding: 2%;
    text-align: center;
    margin-left: 0%;
  }

  .becomeD {
    height: 1200px;
  }

  .staffingtitle {
    width: 100%;
    line-height: 100%;
    margin-top: 5%;
  }

  .subtitle_six_box {
    margin-top: 5%;
  }

  .team_rol {
    text-align: center;
    width: 100%;
  }

  .team_names {
    width: 100%;
    text-align: center;
  }

  .socialmediathree {
    justify-content: space-between;
    width: 100%;
    margin-left: 0%;
  }

  .socialmedia2 {
    width: max-content;
    justify-content: space-between;
    margin-left: 0%;
    margin-right: 5%;
    flex-direction: column;
  }

  .boxcontactandlocations {
    border-radius: 40px;
    width: 450px;
    height: 230px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .staffing {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
  }

  .workingclose {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 24px;
    text-align: center;

  }

  .Careerss {
    margin-bottom: 0%;
    margin-top: 6%;
    width: 60%;
  }

  .We_understand {
    font-size: 150%;
    width: 70%;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 10%;
  }

  .iconsContact {
    font-size: 400%;

  }

  .team_pics_box {

    height: 3500px;

  }

  .carrnametext {
    font-size: 18px;
    padding-top: 10px;
    width: 100%;
  }

  .carrname {
    font-size: 26px;
  }

  .carousel5 {

    justify-content: space-evenly;
  }

  .text_six_box {
    font-size: 26px;
    width: 80%;
    text-align: justify;
  }

  .info_six_sox_OurServices {
    font-size: 22px;
    width: 100%;
    padding: 8%;
  }

  .Join_our {
    font-size: 80px;
    width: 95%;
    text-align: right;
    padding-right: 0px;

  }

  .oportunity {
    text-align: justify;
    font-size: 20px;
    text-align: right;
    margin-left: 0px;
    width: 95%;

  }

  .Form {
    width: 70%;
    height: 70%;
    margin-top: 20%;
    margin-bottom: 5%;
  }

  .gototest {
    margin-left: 0%;
    align-items: center;
    width: 95%;
    margin-top: 20%;
  }

  .Contact {
    width: 70%;
    margin-bottom: 3%;
    margin-top: 1%;
  }

  .tobededicated {
    margin-left: 115px;
    width: 100%;

    margin-bottom: 10%;
  }

  .inputsForm {
    flex-direction: column;
    width: 100%;
  }

  .Officeimg {
    margin-top: 3%;
    width: 70%;
    margin-bottom: 3%;
  }

  .btnapplynow {
    margin-left: 25%;
  }

  .contactandsucces {
    margin-top: 3%;
    margin-bottom: 4%;
  }

  .boxImagen {
    margin-bottom: 10%;
  }

  .applynowbutton {
    margin-left: 25%;
    margin-bottom: 0%;
  }

  .BoxJoinTeam {
    height: 700px;

  }

  .PartnersSponsors {
    flex-direction: column;
    margin-bottom: 10%;
    padding-left: 2%;
    padding-right: 2%;
    height: max-content;
  }

  .Partners {

    width: 100%;
    margin-top: 10%;

  }


  .fourpartners {
    flex-direction: column;
    width: 180px;

  }

  .foursponsors {
    height: 188px;
    width: 180px;
  }

  .partners_sponsors_Font {
    font-size: 22px;
    text-align: center;
  }

  .btnbluecontact {
    margin-left: 38%;
  }

  .borderleftsponsord {
    border-left: 0px gray solid;
    padding-left: 0%;

  }


}





































/*mobile*/

@media only screen and (min-width: 360px) and (max-width: 767px) {


  body {
    width: 130%;
  }




  .workingclose {
    width: 340px;
    font-size: 20px;
    text-align: center;
  }

  .staffing {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;

  }

  .staffingflexbox {
    width: 100%;
    margin-left: 0%;
    width: 100%;
    text-align: center;
    align-items: center;
  }

  .ImagePerson1Home {
    flex-direction: column;
    width: 70%;
    height: max-content;
  }

  .Woman_1 {
    visibility: hidden;
  }


  .navbarflex {
    width: 100%;
    display: none;
    justify-content: space-between;
  }

  .navbarfivesections {
    visibility: hidden;

  }

  .hamburguerflex {
    padding: 0%;
    margin-left: -400px;
    visibility: visible;
    margin-right: 20px;

  }

  .Meeting_your {
    font-size: 45px;
    width: 80%;
    text-align: center;
    line-height: 120%;
  }

  .hiring_through {
    font-size: 22px;
    width: 300px;
  }

  .flexfourpoints {
    flex-direction: column;
    text-align: center;
  }

  .Officeimg {
    width: 70%;
    margin-bottom: 1%;
  }

  .testimonies {
    width: 350px;
    height: 730px;
    padding: 2%;
    border: 4px #2c3470 solid;
  }

  .carrnametext {
    font-size: 18px;
    padding-top: 30px;
    width: 100%;
  }

  .carouselbox {
    height: max-content;
    width: 100%;
  }

  .carrname {
    font-size: 26px;
  }

  .WantToBe {
    font-size: 50px;
    text-align: center;
    width: 105%;
    margin-top: 5%;

  }

  .maindedicated {
    font-size: 60px;

    width: 100%;
  }

  .Work_with_us {
    margin-top: 5%;
    margin-bottom: 0%;
    font-size: 20px;
    width: 105%;
    margin-left: 0.5%;
    line-height: 110%;
    font-weight: 500;

  }

  .map_and_contact_gray {
    flex-direction: column-reverse;
  }

  .mapformwhite {
    flex-direction: column;
  }

  .explore_partnership {
    font-size: 35px;
    width: 100%;
    text-align: center;
    line-height: 110%;
  }

  .briefly {
    font-size: 22px;
    width: 100%;
    text-align: center;
  }

  .footer {
    height: max-content;
    flex-direction: column;
    background-color: white;
  }

  .AboutUs {
    font-size: 70px;
    text-align: center;
    width: max-content;
  }

  .We_understand {
    font-size: 150%;
    width: 90%;
    padding-left: 0%;
    padding-right: 0%;
    margin-bottom: 15%;
    margin-top: 10%;
  }

  .About_us_1 {
    visibility: visible;
    margin-left: 0%;
    width: 70%;

  }

  .achieve_and_communication {
    font-size: 44px;
    text-align: center;
    width: 100%;
    margin-top: 10%;
    margin-bottom: 6%;
  }

  .hiringtext {
    color: #666666;
    font-size: 22px;
    margin-bottom: 5%;
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
  }

  .carouselboxfive {
    width: 390px;
  }

  .achievesix {
    width: max-content;
    flex-direction: column;

  }

  .the_team {
    width: max-content;
    flex-direction: column;
    font-size: 65px;
    align-items: center;
    margin-top: 12%;
    margin-bottom: 5%;
  }

  .WePromote {
    font-size: 18px;
    width: 80%;
    text-align: justify;
    margin-bottom: 0px;
  }

  .teampics {
    width: 80%;
    height: 700px;
    background: #f2f2f2;
    position: relative;
    margin-left: 0%;
    margin-bottom: 0%;
  }

  .contactbox {
    width: 100%;
    height: max-content;
    flex-direction: column;
    margin-top: 12%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .OurServices {
    font-size: 60px;
    width: 100%;
    margin-bottom: 3%;

  }

  .Engaging_subtitle {
    font-size: 20px;
    width: 300px;
    text-align: center;

  }

  .Our_Services {
    width: 85%;
    margin-top: 20%;

  }

  .Dude_1 {
    visibility: hidden;
  }

  .Ready-Partnership-Customer {
    font-size: 45px;
    text-align: center;
    width: 100%;
    margin-top: 15%;
  }

  .Team_Support {
    font-size: 50px;
    margin-top: 3%;
    text-align: center;
    padding-left: 0%;


  }

  .partner_perks {
    width: 100%;
    margin-left: 15%;
    margin-top: -25%;
    margin-bottom: 8%;
  }

  .text_six_box {
    font-size: 22px;
    width: 220px;
    text-align: justify;
  }

  .IT_Support {
    font-weight: bold;
    font-size: 50px;
    color: #2c3470;
    font-family: 'Mitogen Display', sans-serif;
    margin-top: 3%;
    text-align: left;
  }

  .subtitle_six_box {
    font-size: 30px;
    width: 100%;
    text-align: left;
    margin-top: 5%;
  }

  .whatmakeus {
    width: 100%;
    padding-top: 0%;
    padding-bottom: 0%;
    flex-direction: column;
    align-items: center;

  }

  .cardmakeus {
    width: 340px;
    margin-left: 0px;
    margin-bottom: 10%;
    padding-top: 15%;
    height: max-content;

  }

  .WeAre {
    font-size: 70px;
    width: 100%;
  }

  .text_careers_info {
    width: 80%;
    font-size: 20px;
  }

  .Careerss {
    width: 70%;
  }

  .Girl_2 {
    visibility: hidden;
  }

  .Become_Dedicated {
    font-size: 70px;
    padding-top: 20%;
    width: 100%;
  }

  .becomeD {
    width: max-content;
    flex-direction: column;
    margin-left: 0%;
    height: max-content;
    text-align: center;
  }

  .become_numbers {
    padding: 2%;
    margin-right: 2%;
  }

  .talk01 {
    font-size: 26px;
    width: 100%;

  }

  .talk01sub {
    font-size: 18px;
    width: 100%;
  }

  .prepare02 {
    font-size: 26px;
    width: 100%;
    margin-top: 25%;

  }

  .prepare02sub {
    font-size: 18px;

  }

  .interview03 {
    font-size: 26px;
    width: 100%;
    margin-top: 32%;


  }

  .interview03sub {
    font-size: 18px;

  }

  .hired04 {
    font-size: 26px;
    width: 100%;
    margin-top: 30%;

  }

  .hired04sub {
    font-size: 18px;
  }

  .boxnumbers {
    width: 100%;

  }

  .gototest {
    margin-left: 0%;
    align-items: center;
    width: 75%;
    margin-top: 10%;
  }

  .Join_our {
    font-size: 70px;
    width: 100%;
    text-align: center;
    padding-right: 0px;
  }

  .Form {
    visibility: hidden;
  }

  .Contact {
    width: 70%;
    margin-bottom: 3%;
    margin-top: 3%;
  }

  .socialmedia {
    width: 90%;
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 3%;
    flex-direction: row;
    margin-left: 0px;
  }

  .contactlocations {
    width: 100%;
    padding-left: 5%;
    flex-direction: column;
  }

  .btnrsbox {
    width: 50%;
    background: #424ea8;
    cursor: pointer;
    align-content: center;
    align-items: center;
    padding-left: 2.5%;
    border-radius: 10px;
    padding-top: 2%;
  }

  .socialmediathree {
    justify-content: space-between;
    width: 90%;
    margin-bottom: 20px;
  }

  .Woman_2 {
    visibility: hidden;
  }

  .boxcontactandlocations {
    width: 95%;
    background-color: #f2f2f2;
    padding: 6%;
    margin-top: 4%;
    margin-bottom: 4%;
    height: max-content;
  }

  .boxImagen {
    width: max-content;
  }

  .sixImagen {
    width: 350px;
  }

  .subtitle_six_text {
    font-size: 18px;
    width: 350px;
    margin-bottom: 15%;
  }

  .boxcontactus {
    width: 95%;
    height: 380px;
    margin-bottom: 20px;
  }

  .mapsbgg {
    width: 90%;
    border: 6px #2c3470 solid;
    border-radius: 15px;
    margin-bottom: 10px;
    margin-left: 5%;
  }

  .btn-blue {
    padding: 8px 30px;
    margin-left: 0%;
    font-size: 16px;
    font-weight: bold;
    width: max-content;
    margin-top: 0%;
    margin-bottom: 4%;
    border-radius: 8px;
  }

  .staffing_needs {
    width: 250px;
    font-size: 22px;

  }

  .titlecarousel {
    font-size: 16px;
    color: #424ea8;
  }

  .stars {
    font-size: 30px;
    letter-spacing: 6px;
    justify-content: space-between;
    width: 70%;

  }

  .btnAN {
    padding: 5px 70px;
    border-radius: 8px;
    font-size: 20px;
    width: 250px;
    margin-top: 10%;
    margin-bottom: 0%;
    margin-left: 0%;
  }

  .staffingtitle {
    font-size: 55px;
    width: 90%;
    line-height: 100%;
    margin-top: 0%;
  }

  .tobededicated {
    width: max-content;
    font-size: 28px;
    align-items: center;
    height: 20%;
    margin-bottom: 5%;
    margin-bottom: 60%;
  }

  .boxabout {
    height: max-content;
    width: 100%;
    padding-bottom: 5%;
    padding-left: 0%;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 25%;
  }

  .carousel5 {
    width: 375px;
    height: 670px;
    margin-left: 0%;
    margin-right: 1%;
    justify-content: space-evenly;
  }

  .subnames6 {
    font-size: 20px;
  }

  .StepByStep {
    width: max-content;
    font-size: 55px;
    width: 100%;
    text-align: center;
  }


  .oportunity {
    text-align: justify;
    font-size: 20px;
    text-align: center;
    margin-left: 0px;
    width: 100%;
  }

  .inquiries {
    font-size: 20px;
    width: 80%;
    text-align: justify;
  }

  .ContactInfo {
    font-size: 22px;

  }


  .directions {
    font-size: 22px;
  }

  .boxsixaboutus {
    padding-left: 15%;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-top: 0%;
    margin-left: 2%;
    width: 325px;
  }

  .btn-blue-contact {
    margin-left: 0%;
    font-size: 20px;
    margin-top: 0%;
  }

  .info_six_sox_OurServices {
    font-size: 22px;
    width: 100%;
    padding: 8%;
  }

  .pictureTeam {
    width: 350px;
    margin-left: -10%;
  }

  .team_names {
    width: 100%;
    text-align: center;
  }

  .team_rol {
    text-align: center;
    width: 100%;
  }

  .what_make_us_phrase {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .BoxJoinTeam {
    flex-direction: column;
    margin-top: -50%;
  }

  .socialmedia2 {
    width: 70%;
    justify-content: space-between;
    margin-left: 0%;
    flex-direction: row;
  }

  .ContactName {
    font-size: 150%;
    color: black;
    font-family: 'Mitogen Display', sans-serif;
    font-weight: 500;
    margin-bottom: 2%;
    margin-top: 0%;
  }

  .the_teamm {
    width: 100%;
    flex-direction: column;
    margin-top: 0%;
    margin-bottom: 5%;
    align-items: center;
    height: 50%;
  }

  .iconsContact {
    font-size: 400%;
  }

  .team_pics_box {
    justify-content: space-around;
    height: max-content;
    padding-bottom: 10%;
  }

  .boxteams {
    height: 3000px;
    width: 100%;
    flex-direction: column;
    margin-top: 10%;
  }

  .image_aboutUs {
    border-radius: 30px;
    height: 230px;
    width: 100%;
  }

  .inputsForm {
    flex-direction: column;
    width: 100%;
  }

  .btnbluecontact {
    margin-left: 0%;
  }

  .btnsendandtest {
    margin-left: 0%;
  }

  .PartnersSponsors {
    flex-direction: column;
    margin-bottom: 65%;
  }

  .Partners {
    width: 100%;
    margin-top: 15%;
  }

  .flextwopartners {

    flex-direction: column;
    height: max-content;
  }

  .spacePartnersSponsors {
    justify-content: space-evenly;
    margin-top: 3%;
  }

  .partners_sponsors_Font {
    font-size: 20px;
    text-align: center;
  }

  .fourpartners {
    width: 188px;
  }

  .foursponsors {
    height: 180px;
  }

  .btnapplynow {
    margin-left: 0%;
  }

  .applynowbutton {
    margin-left: 0;
  }

  .borderleftsponsord {
    border-left: 0px gray solid;
    padding-left: 0%;

  }
}